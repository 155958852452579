import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
    enableProdMode();
}

// Add GTM script to <head>
if (environment.gtm) {
    const script = document.createElement('script');
    script.innerHTML = environment.gtm;
    document.head.prepend(script);
}

// Add GTM noscript to <body>
if (environment.gtmNoscript) {
    const noscript = document.createElement('noscript');
    noscript.innerHTML = environment.gtmNoscript;
    document.body.prepend(noscript);
}

platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .catch((err) => console.log(err));
