import { Component } from '@angular/core';
import {
    Event,
    NavigationCancel,
    NavigationEnd,
    NavigationError,
    NavigationStart,
    Router,
} from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { filter } from 'rxjs/operators';
import packageJson from '../../package.json';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent {
    isLoading = false;
    title = 'Sonar';
    version = packageJson.version;
    window: any;

    constructor(
        private router: Router,
        private translate: TranslateService,
    ) {
        this.translate.setDefaultLang('en');

        this.router.events
            .pipe(
                filter(
                    (
                        event: Event,
                    ): event is
                        | NavigationStart
                        | NavigationEnd
                        | NavigationCancel
                        | NavigationError =>
                        event instanceof NavigationStart ||
                        event instanceof NavigationEnd ||
                        event instanceof NavigationCancel ||
                        event instanceof NavigationError,
                ),
            )
            .subscribe((event) => {
                this.isLoading = event instanceof NavigationStart;
            });
    }
}
