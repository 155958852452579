import { Observable, BehaviorSubject, throwError } from 'rxjs';
import { take, filter, catchError, switchMap, finalize } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import {
    HttpInterceptor,
    HttpRequest,
    HttpHandler,
    HttpSentEvent,
    HttpHeaderResponse,
    HttpProgressEvent,
    HttpResponse,
    HttpUserEvent,
    HttpErrorResponse,
} from '@angular/common/http';
import { TokenService } from './token.service';
import { Token } from '../../../core/models/token/token';
import { Router } from '@angular/router';
import { environment } from '../../../../environments/environment';

declare var gigya: any;

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
    isRefreshingToken = false;
    apiKey = environment.apiKey;
    tokenSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);

    constructor(
        private injector: Injector,
        private router: Router,
        private tokenService: TokenService,
    ) {}

    addToken(
        req: HttpRequest<any>,
        token: string,
        uid: string,
        oauth: string,
    ): HttpRequest<any> {
        if (
            oauth !== null &&
            token &&
            req.url.indexOf('/oauth/token') < 0 &&
            req.url.indexOf('/stopModeUpgrade') > 0 &&
            !req.url.includes('oauthv2/cors/token')
        ) {
            return req.clone({
                setHeaders: {
                    uid: uid,
                    jwt: token,
                    apikey: this.apiKey,
                    Authorization: `Bearer ${oauth}`,
                    apiCache: 'true',
                    'Content-Type': 'application/json',
                },
            });
        } else if (
            oauth !== null &&
            token &&
            req.url.indexOf('/oauth/token') < 0 &&
            !req.url.includes('oauthv2/cors/token')
        ) {
            return req.clone({
                setHeaders: {
                    uid: uid,
                    jwt: token,
                    Authorization: `Bearer ${oauth}`,
                    apikey: this.apiKey,
                    'Content-Type': 'application/json',
                },
            });
        } else if (token && oauth === null && environment.envName === 'local') {
            return req.clone({
                setHeaders: {
                    uid: uid,
                    jwt: token,
                    apikey: this.apiKey,
                    'Content-Type': 'application/json',
                },
            });
        } else {
            return req;
        }
    }

    intercept(
        req: HttpRequest<any>,
        next: HttpHandler,
    ): Observable<
        | HttpSentEvent
        | HttpHeaderResponse
        | HttpProgressEvent
        | HttpResponse<any>
        | HttpUserEvent<any>
    > {
        if (this.tokenService.getToken() && this.tokenService.getUid()) {
            return next
                .handle(
                    this.addToken(
                        req,
                        this.tokenService.getToken(),
                        this.tokenService.getUid(),
                        this.tokenService.getOauth2Token(),
                    ),
                )
                .pipe(
                    catchError((error) => {
                        if (error instanceof HttpErrorResponse) {
                            switch (error.status) {
                                case 400:
                                    return this.handle400Error(error);
                                case 401:
                                    return this.logoutUser();
                                case 403:
                                    return this.handle403Error(error);
                                case 404:
                                    return this.handle404Error(error);
                                case 503:
                                    return this.handle503Error(error);
                                default:
                                    return throwError(error);
                            }
                        } else {
                            return throwError(error);
                        }
                    }),
                );
        } else {
            if (req.url.includes('/i18n/')) {
                return next.handle(req);
            }
            if (
                req.url.indexOf('/oauth/token') < 0 &&
                !(
                    req.url.endsWith('/api/usuarios-token/token') &&
                    req.method === 'POST'
                ) &&
                !(
                    req.url.includes('/api/usuarios-token/update') &&
                    req.method === 'POST'
                )
            ) {
                return this.logoutUser();
            } else {
                return next.handle(req);
            }
        }
    }

    handle400Error(error) {
        if (
            error &&
            error.status === 400 &&
            error.error &&
            error.error.error === 'invalid_grant'
        ) {
            return this.logoutUser();
        }
        return throwError(error);
    }

    handle401Error(req: HttpRequest<any>, next: HttpHandler) {
        if (!this.isRefreshingToken) {
            this.isRefreshingToken = true;
            this.tokenSubject.next(null);
            const tokenService = this.injector.get(TokenService);
            return tokenService.refreshToken().pipe(
                switchMap((newToken: Token) => {
                    if (newToken) {
                        this.tokenSubject.next(newToken.access_token);
                        return next.handle(
                            this.addToken(
                                this.getNewRequest(req),
                                newToken.access_token,
                                null,
                                null,
                            ),
                        );
                    }
                    return this.logoutUser();
                }),
                catchError((error) => {
                    return this.logoutUser();
                }),
                finalize(() => {
                    this.isRefreshingToken = false;
                }),
            );
        } else {
            return this.tokenSubject.pipe(
                filter((token) => token != null),
                take(1),
                switchMap((token) => {
                    return next.handle(
                        this.addToken(
                            this.getNewRequest(req),
                            token,
                            null,
                            null,
                        ),
                    );
                }),
                catchError((error) => {
                    return throwError(error);
                }),
            );
        }
    }

    handle403Error(error) {
        this.router.navigate(['/dashboard']);
        return throwError(null);
    }

    handle404Error(error) {
        return throwError(error);
    }

    handle503Error(error) {
        if (error) {
            this.router.navigate(['/maintenance']);
        }
        return throwError(error);
    }

    getNewRequest(req: HttpRequest<any>): HttpRequest<any> {
        return new HttpRequest<any>(req.method, req.url, req.body);
    }

    logoutUser() {
        gigya.accounts.logout();
        sessionStorage.removeItem('uid');
        sessionStorage.removeItem('jwt');
        sessionStorage.removeItem('filter');
        sessionStorage.removeItem('page');
        const tokenService = this.injector.get(TokenService);
        tokenService.removeToken();

        let url =
            this.router.getCurrentNavigation() &&
            this.router.getCurrentNavigation().extractedUrl.toString();
        if (!url || url === '/login') {
            url = this.router.url;
        }
        if (url) {
            this.tokenService.saveExtractedUrl(url);
        }

        this.router.navigate(['/login']);
        return throwError(null);
    }
}
