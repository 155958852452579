// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.version {
  bottom: 6px;
  color: #989898;
  font: 400 16px/1.2 "Source Sans Pro", sans-serif;
  font-size: 12px;
  position: fixed;
  right: 8px;
  text-shadow: 0 0 2px #fff;
  z-index: 99;
}`, "",{"version":3,"sources":["webpack://./src/app/app.component.scss","webpack://./src/styles/settings/config.scss"],"names":[],"mappings":"AAEA;EACI,WAAA;EACA,cCcmB;EDbnB,gDCHA;EDIA,eAAA;EACA,eAAA;EACA,UAAA;EACA,yBAAA;EACA,WAAA;AADJ","sourcesContent":["@import 'settings/config';\n\n.version {\n    bottom: 6px;\n    color: $color-tertiary-light;\n    font: $font-primary;\n    font-size: 12px;\n    position: fixed;\n    right: 8px;\n    text-shadow: 0 0 2px #fff;\n    z-index: 99;\n}\n","// Fonts\n$font-primary:\n    400 16px/1.2 'Source Sans Pro',\n    sans-serif;\n$font-secondary: 'Oswald', sans-serif;\n\n// Colors name\n$color-tory-blue: #1747a1;\n$color-tory-blue-dark: #1646a1;\n$color-athens-gray: #ebecee;\n\n// Colors application\n$color-primary: #20a0a0;\n$color-primary-accent: #d31245;\n$color-accent: #20a0a0;\n$color-accent-hover: #098787;\n$color-secondary: #272727;\n$color-tertiary: #1a1818;\n$color-tertiary-light: #989898;\n$color-text: #272727;\n$color-text-secondary: #848484;\n$color-second-gray: #616161;\n$color-background: #f7f7f7;\n$color-background-light: #f2f2f2;\n$color-success: #42c678;\n$color-notification: $color-tory-blue-dark;\n$color-progress: #a88c5f;\n$color-error: #e91152;\n$color-alert: #ff9800;\n$color-alert-light: #fcbe00;\n\n$color-form-input-label: #878787;\n$color-form-input-border: #dfe0e1;\n$color-form-warning: #e81253;\n\n$color-badge-not-started: $color-tory-blue;\n$color-badge-in-progress: #e81253;\n$color-badge-completed: #42c778;\n\n$color-plan-draft: #e61a55;\n$color-plan-pre-execution: #1a499e;\n$color-plan-in-execution: #fdac2a;\n$color-plan-post-execution: #b24ce8;\n$color-plan-mye: #23a0a0;\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
