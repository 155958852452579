export const environment = {
    envName: 'dev',
    production: false,
    serviceUrl: 'https://dev-apim.integration.bacardiapps.com/v1/Sonar',
    clientId: 'client_api_web',
    clientSecret: 'somesecret',
    pageNumElements: [8],
    apiKey: 'KQJMK78ySAiq8GbVkxBtegglaAJ20af6',
    gtm: null,
    gtmNoscript: null,
    oauth2ClientId: 'KQJMK78ySAiq8GbVkxBtegglaAJ20af6',
    oauth2ClientSecret: 'kj4ApiSwQgnB1P15',
    externalUrls: {
        onePager: 'https://app.powerbi.com/groups/ffb6e826-f0bb-4831-aabc-e6faa5b0628d/reports/1cd4c7df-8b4d-4cb3-be39-75bbe4e5797e/ReportSection1061f72b8b212771a1d3',
        activityTracker: 'https://app.powerbi.com/groups/ffb6e826-f0bb-4831-aabc-e6faa5b0628d/reports/89fac0c2-3388-4696-8f78-8ddc9bdf5917/ReportSection199c265e8018e3a52ea4',
        roiSimulator: 'https://app.powerbi.com/groups/cd008e4c-725c-453f-947c-057e2ed5354a/reports/e789b24f-7e01-4622-b6ca-d0d0f32302db/ReportSection47a6e4b5d5a07e25c9ff?experience=power-bi',
    },
};
