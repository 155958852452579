import { registerLocaleData } from '@angular/common';
import {
    HTTP_INTERCEPTORS,
    HttpClient,
    HttpClientModule,
} from '@angular/common/http';
import localeEn from '@angular/common/locales/en';
import { APP_INITIALIZER, LOCALE_ID, NgModule } from '@angular/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { TokenInterceptor } from './shared/services/security/token.interceptor';
import { TokenService } from './shared/services/security/token.service';
import { cloneSesionStorage } from './shared/util/clone-sesion-storage.util';

registerLocaleData(localeEn, 'en');

export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
    declarations: [AppComponent],
    imports: [
        BrowserModule,
        AppRoutingModule,
        HttpClientModule,
        BrowserAnimationsModule,
        MatProgressSpinnerModule,
        TranslateModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient],
            },
        }),
    ],
    providers: [
        TokenService,
        {
            provide: APP_INITIALIZER,
            useFactory: () => {
                return cloneSesionStorage;
            },
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: TokenInterceptor,
            multi: true,
        },
        { provide: LOCALE_ID, useValue: 'en' },
        // { provide: Window, useValue: window }
        // { provide: RouteReuseStrategy, useClass: CustomReuseStrategy },
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
